import React from 'react'
import Layout from '../components/resuable/layout/layout'
import { graphql } from 'gatsby'
import SEO from '../components/resuable/seo/seo'
import Hero from '../components/resuable/hero/hero'
import Section from '../components/resuable/section/section'
import Row from '../components/resuable/row/row'
import Joinus from '../components/resuable/joinus/joinus'

const A2i = ({ data }) => {
  const { title, content, yoast_meta } = data.wordpressPage;
  return (
    <Layout>
      <SEO
        title={yoast_meta.yoast_wpseo_title}
        description={yoast_meta.yoast_wpseo_metadesc}
      />
      <Section
        fullwidth={true}
        css={`
          display: block;
        `}
      >
        <Row>
          <Hero
            title={`What is A2I?`}
          />
        </Row>
        <Row
          fullwidth={false}
        >
          <div
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Row>
      </Section>
      <Joinus />
    </Layout>
  )
}

export const query = graphql`
  query a2iQuery {
    wordpressPage(slug: {eq: "a2i"}) {
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`

export default A2i
